import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Colorado Architectural Services"
      description="JKA Design does not stop with just designing your dream home. We also help with the construction process, permits, and more. Get in touch with us.
	  "
    />
    <div class="page-headline">
      <div class="container">
        <div class="section-heading text-center">
          <h6 class="font-weight-bold text-uppercase text-white-50 flair">
            Services
          </h6>
          <h1>
            <strong>JKA Design Architectural Services in Colorado</strong>
          </h1>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-12 mb-4">
            <h4>Some select JKA projects</h4>
            <div class="row justify-content-around py-5">
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/sketches-rendereings-and-models">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/9ed9f6ed728c3dc0d34ad27070e4e63c9736f419-1030x616.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Sketches, Rendereings and Models</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/boulder-mountain-cabin">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/d9bc7291ce6a550fd4967d1774ba87f64df4a32a-1030x818.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Boulder Mountain Cabin</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/contemporary-entry-and-master-addition">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/99d4fd016b9d3bc9cbc41710eb33b6300ec65546-1030x773.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Contemporary Entry and Master Addition</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <h4>
              Jeffry K. Abrams, Architect is a full-service architectural firm
              offering the following additional architectural services:
            </h4>
            <div class="row justify-content-around py-5">
              <div class="col-md-6">
                <ul className="services-list">
                  <li>Planning &amp; Zoning Consultation and Advising</li>
                  <li>Code research</li>
                  <li>Site Planning</li>
                  <li>Architectural Design</li>
                  <li>Commercial Building Design</li>
                  <li>Light Commercial Architectural Design</li>
                  <li>Multifamily Design</li>
                  <li>Preliminary Design</li>
                  <li>Schematic Design</li>
                  <li>Custom and Luxury Residential Design</li>
                  <li>Residential Addition – Design and consultation</li>
                  <li>Remodeling and Renovation Design</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul className="services-list">
                  <li>Construction Documents</li>
                  <li>Working Drawings</li>
                  <li>Construction Detailing</li>
                  <li>As Built Measuring and Drawings</li>
                  <li>Energy Consulting</li>
                  <li>Passive Solar Consultation and Design</li>
                  <li>Bidding or Negotiation</li>
                  <li>Construction Contract Consultancy</li>
                  <li>Site Observation and Advising</li>
                  <li>Design Review Consultation</li>
                  <li>Forensics</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="review-snippet padding-50 text-center text-white">
      <div className="container">
        <p className="text-white">
          <i className="fa fa-quote-left mr-3" />
          <em>
            “My wife and I asked Jeff Abrams to present ideas for the upgrade
            and improvements to the exterior of our 90 year old house. We met
            with Jeff and his assistant twice to discuss alternative
            possibilities, including adding architectural details to the
            exterior and redesigning a patio roof. Jeff and his assistant
            created several elevation drawings of the front of the house and the
            patio roof design, showing alternate detail additions. The CADD
            generated idea sketch drawings have been quite helpful in making our
            decisions with regard to choosing the detail additions that we will
            proceed with.”
          </em>
          <i className="fa fa-quote-right ml-3" />
        </p>
      </div>
      <em>–Devin R., Client</em>
    </div>
  </Layout>
)

export default SecondPage
